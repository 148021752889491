import axios from "axios";
import configuration from "utils/configuration";
import { User, UserParticipant } from "types/user";
import { DocumentType, Deal, DealConvertedByClient } from "types/deal";
import {
    AuthResponse,
    SendEmailForResetResponse,
    AddDealFilePayload,
    DealsResponse,
    SetAppointmentPayload,
    ClosingDateType,
    getDealDocumentsTypesResponse,
    ActivityLogResponse,
    StaffResponse,
    setStageResponse,
    setAssignTeamPayload,
    UpdateDealClientDataPayload, UpdateDealDataPayload,
} from "./types_TEMP";
import { makeQueryString } from "utils/makeQueryString";
import { DealNumber } from "types/reduxActions/deals";
import { ClientIntakeStates, OnboardingTask, OnboardingTaskStatus, State } from "types/intakeForm";
import { PointTransaction } from "types/pointTransaction";
import { PartnerPage, PartnerPageWithUser } from "types/partnerPage";
import { QuoteResult } from "types/quote";
import { QrData } from "customerPortal/pages/UserSecurity/SecurityMFAPasswordModal";
import { AdditionalDocument } from "components/Dashboard/AdditionalDocuments/AdditionalDocuments";
import {AgreementFile} from "components/Information/Quote/QuoteUpload";
import {IntegrationDeal} from "types/integrations";

export const baseURL = configuration.apiBaseUrl;

export type {Lender} from "./types_TEMP";
export type {UpdateDealClientDataPayload} from "./types_TEMP";

// AUTH API //
export const LogIn = (userData: {email: string; password: string}) => {
  return axios.post<AuthResponse>(`/login`, userData);
};

export const pricingSubmit = (userData: any) => {
  return axios.post<AuthResponse>(
    `/integrations/personal-page/submit`,
    userData,
  );
};

export const sendEmailForReset = (email: string) => {
  return axios.post<SendEmailForResetResponse>(`/request-password-reset`, {
    email,
  });
};

export const sendPasswordForConfirm = (
  new_password: string,
  reset_token: string,
) => {
  const data = {new_password, reset_token};
  return axios.post(`/confirm-password-reset`, data);
};

export const verifyResetPasswordToken = (reset_token: string) => {
  const data = {reset_token};
  return axios.post<{ status: string }>(`/verify-password-reset`, data);
};

// DEALS API //
export const dropDeal = (dealID: number, cancel_description?: string, isTest?: boolean) => {
  return axios.post(`/deals/${dealID}/archive-status`, {
    status: true,
    cancel_description,
    is_test: !!isTest
  });
};
export const addDealFile = (dealID: number, payload: AddDealFilePayload) => {
  return axios.post<Array<DocumentType>>(`/deals/${dealID}/upload`, payload);
};
export const createDealRequestedDocs = (
  dealID: string,
  payload: {requested_documents: AdditionalDocument[]},
) => {
  return axios.post(`/deals/${dealID}/request-doc`, payload);
};

export const getPartnerPage = (slug: string) => {
  return axios.get<PartnerPageWithUser>(`/integrations/partners/${slug}`);
};

export const convertDeal = (dealID: number, payload: {status: boolean}) => {
  return axios.post<Deal>(`/deals/${dealID}/change-status`, payload);
};
export const cancelAppointment = (dealID: number, appointmentID: number) => {
  return axios.post<Deal>(
    `/deals/${dealID}/cancel-appointment/${appointmentID}`,
  );
};
export const setAppointment = (
  dealID: number,
  payload: SetAppointmentPayload,
) => {
  return axios.post<Deal>(`/deals/${dealID}/set-appointment`, payload);
};
export const getDealByID = (dealID: number) => {
  return axios.get<Deal>(`/deals/${dealID}`);
};

export const getDealDocumentsTypes = () => {
  return axios.get<getDealDocumentsTypesResponse>(
    `/agreements/get-uploadable-document-types`,
  );
};

export const getDocumentsTypes = (params: { dealType?: string, province?: string } = {}) => {
  return axios.get(`/document-types`, { params });
};

export const getActivityLogsByID = (dealID: number, page: number) => {
  return axios.get<ActivityLogResponse>(
    `/deals/${dealID}/activity-log?page=${page}`,
  );
};
export const getDeals = (
  status: boolean,
  page: number,
  query = "",
  sortBy: string,
  sortOrder: boolean | "asc" | "desc",
  user_id: number | null,
  params?: { newDeals: boolean } | { showClosed: boolean } | { archive: boolean } | { onboarding: boolean },
  abortController?: AbortController,
) => {
  const url = makeQueryString({
    page,
    sortBy,
    sortOrder:
      typeof sortOrder === "boolean" ? (sortOrder ? "desc" : "asc") : sortOrder,
    search: query,
    user_id,
  });

  return axios
    .get<DealsResponse>(`/deals${url}${params ? "&" : "?"}status=${status}`, {
      params,
      signal: abortController?.signal
    })
    .catch((e) => {
      console.error(e);
    });
};
export const getDealsClosed = (
  query = "",
  page: number,
  sortBy = "",
  sortOrder: boolean,
  user_id: number | null,
) => {
  const url = makeQueryString({
    page,
    sortBy,
    sortOrder: sortOrder ? "desc" : "asc",
    search: query,
    showClosed: true,
    user_id,
  });
  return axios.get<DealsResponse>(`/deals${url}`);
};
export const getBlockedTimeSlots = (dealId: number, payload: unknown) => {
  return axios.post(`/deals/${dealId}/appointment/block-time`, payload);
};
export const getNewDeals = (
  query = "",
  page: number,
  sortBy = "",
  sortOrder: boolean,
  user_id: number | null,
) => {
  const url = makeQueryString({
    page,
    sortBy,
    sortOrder: sortOrder ? "desc" : "asc",
    search: query,
    newDeals: true,
    user_id,
  });
  return axios.get<DealsResponse>(`/deals${url}`);
};

export const searchDeal = (query: string) => {
  return axios.get<DealsResponse>(`/deals?search=${query}`);
};
export const getStaff = (with_admin?: boolean) => {
  return axios.get<StaffResponse>(
    `/staff${with_admin ? "?with_admin=true" : ""}`,
  );
};
export const unassignStaff = (dealID: number, stuffID: number) => {
  return axios.post<Deal>(`/deals/${dealID}/unassign-staff/${stuffID}`);
};
export const unassignTeamMember = (dealID: number, memberID: number) => {
  return axios.post<Deal>(`/deals/${dealID}/unassign-team/${memberID}`);
};

export const saveRevertStage = (dealID: number, payload: unknown) => {
  return axios.post(
    `/deals/${dealID}/revert-stage`,
    payload,
  );
};

export const setStage = (
  dealID: number,
  stageID: number,
  notification: boolean,
  html_template_id: number,
  sender?: number,
) => {
  let stage = {
    deal_stage_id: stageID,
    html_template_id,
    notification,
    sender,
  };
  return axios.post<setStageResponse>(`/deals/${dealID}/set-stage`, stage);
};
export const setStuff = (
  dealID: number,
  payload: {old: null | unknown; new: number},
) => {
  return axios.post<Deal>(`/deals/${dealID}/assign-staff`, payload);
};

export const setStuffStatus = (
  dealID: number,
  user_id: number,
  payload: {status: boolean},
) => {
  return axios.post<Deal>(`/deals/${dealID}/status-staff/${user_id}`, payload);
};

export const setAssignTeam = (dealID: number, member: setAssignTeamPayload) => {
  const {first_name, last_name, middle_name, phone, role, email} = member;

  let newMember: setAssignTeamPayload = {
    first_name,
    last_name,
    middle_name,
    role,
    phone,
    email,
  };

  return axios.post<UserParticipant>(`/deals/${dealID}/assign-team`, newMember);
};
export const updateDealClientData = (
  dealID: number,
  payload: UpdateDealClientDataPayload | UpdateDealDataPayload,
) => {
  return axios.post<Deal>(`/deals/${dealID}/update`, payload);
};
export const updateDealMemberData = (
  dealID: number,
  payload: unknown,
  memberID: number,
) => {
  return axios.post(`/deals/${dealID}/update-team/${memberID}`, payload);
};
export const getFoldersForDealType = (deal_type: string, province?: string) => {
  const url = `/file-info/get-doc-types?dealType=${deal_type}${province ? `&province=${province}` : ''}`;
  return axios.get(url);
};
// COMMENTS //
export const getComments = (dealID: number) => {
  return axios.get(`/deals/${dealID}/comments`);
};
export const createComments = (
  dealID: unknown,
  text: string,
  isNotify: boolean,
) => {
  let content = {
    content: text,
    notification: isNotify,
  };
  return axios.post(`/deals/${dealID}/comments/create`, content);
};
export const deleteComments = (dealID: number, commentID: number) => {
  return axios.post(`/deals/${dealID}/comments/${commentID}/delete`);
};
// MESSAGES //
export const getMessages = (dealID: number) => {
  return axios.get(`/deals/${dealID}/messages`);
};
export const createMessages = (dealID: number, payload: unknown) => {
  return axios.post(`/deals/${dealID}/messages/create`, payload);
};
export const deleteMessages = (dealID: number, messageID: number) => {
  return axios.post(`/deals/${dealID}/messages/${messageID}/delete`);
};
export const getProfile = () => {
  return axios.get(`/profile`);
};

// Integrations
export const listIntegrationDealsPending = () => {
    return axios.get<IntegrationDeal[]>(`/integration-deals/pending`);
};
export const convertIntegrationDeal = (id: number, data: { deal_type: string; deal_type_tag_id?: number }) => {
    return axios.post<{deal_id: number}>(`/integration-deals/${id}/convert`, data);
};

// Profile
export const updateProfile = (updateData: unknown) => {
  return axios.post(`/profile/update`, updateData);
};
export const updatePassword = (
  oldPassword: string,
  password: string,
  password_confirmation: string,
) => {
  return axios.post(`/profile/update-password`, {
    oldPassword,
    password,
    password_confirmation,
  });
};
export const authGenerateQR = (password: string) => {
  return axios.post<QrData>(`/authenticator/generate-qr`, {password});
};
export const authVerifyQR = (code: string) => {
  return axios.post(`/authenticator/verify-qr`, {code});
};

export const workDocIntegration = (payload: {
  user_id: number;
  password: string;
  password_confirmation: string;
}) => {
  return axios.post(`/profile/create-workdocs-user`, payload);
};
export const connectToExistingWorkdocsIntegration = () => {
  return axios.post(`/profile/connect-workdocs-user`, []);
};
export const connectToExistingBoxIntegration = () => {
  return axios.post(`/profile/connect-box-user`, []);
};
export const hideGuides = () => {
  return axios.post(`/profile/hide-guides`, {});
};
export const getProfilePartnerPage = () => {
    return axios.get<PartnerPage>(`/profile/partner-page`);
}
export const updateProfilePartnerPage = (data: { enabled: boolean; page_name?: string }) => {
    return axios.put<PartnerPage | {}>(`/profile/partner-page`, data);
};

//CONFIG
export const downloadReports = (url: string, type: string, params: unknown = {}) => {
  return axios.get(url, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": type,
      Accept: type,
    },
    params
  });
};

export const downloadDocument = (documentId: number, type: string, downloadUrl: string = '') => {
  return axios.get(downloadUrl || `/agreements/${documentId}`, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": type,
      Accept: type,
    },
  });
};
// CONFIG USERS //
export const getAllUsers = () => {
  return axios.get(`/config/users`);
};
export const getAllProfessionalUsers = () => {
    return axios.get<User[]>(`/config/users?tab=professionals&all=true`);
};
export const getAllLawFirmUsers = () => {
    return axios.get<User[]>(`/config/users?tab=law-firm&all=true`);
};
export const getUser = (userId: string|number) => {
  return axios.get<User>(`/config/users/${userId}`);
};
export const deleteUser = (userID: number) => {
  return axios.post(`/config/users/${userID}/delete`, {});
};
export const updateUser = (data: unknown, userID: number) => {
  return axios.post(`/config/users/${userID}/update`, data);
};
export const createUser = (data: unknown) => {
  return axios.post(`/config/users/create`, data);
};
export const getUserAvatar = (page: number) => {
  return axios.get(`/userpics/${page}`);
};
export const checkUserEmail = (email: string) => {
    return axios.post<{ user_found: boolean }>(`/config/users/check-client-email`, { email });
};
export const getCompaniesList = () => {
  return axios.get("/config/companies");
};
export const updateUserPointTransactions = (userId: number, data: Pick<PointTransaction, 'points' | 'type' | 'label'>) => {
  return axios.post<{point_transaction: PointTransaction, total: number}>(`/config/users/${userId}/points`, data);
};
export const getUserPointTransactions = (userId: number) => {
    return axios.get<PointTransaction[]>(`/config/users/${userId}/points`);
};
export const updateUserPointsFeature = (userId: number, enabled: boolean) => {
    return axios.put<User>(`/config/users/${userId}/points/feature`, { enabled });
};

export const getRoles = (role?: "staff" | "professionals") =>
  axios.get(`/config/roles${role ? `?group=${role}` : ""}`);

// CONFIG DEAL STAGES //
export const getDealStages = () => {
  return axios.get(`/config/deal-stages`);
};
export const updateDealStages = (data: unknown) => {
  return axios.post(`/config/deal-stages/update`, data);
};
// CONFIG NOTIFICATIONS //
export const getNotifications = () => {
  return axios.get(`/config/notifications`);
};
export const updateNotifications = (data: unknown) => {
  return axios.post(`/config/notifications/update`, data);
};
// CONGIF COMMUNICATIONS //
export const getTemplates = (dealType: string) => {
  return axios.get(
    `/config/communications?deal_type=${dealType}&only_active=false`,
  );
};
export const showCommunication = (dealID: number) => {
  return axios.get(`/config/communications/${dealID}`);
};
export const getCommunications = (dealID: number) => {
  return axios.get(`/config/communications?deal_stage_id=${dealID}`);
};
export const getActiveTemplates = (dealID: number, stageID: number) => {
  return axios.get(
    `/config/communications?deal_id=${dealID}&deal_stage_id=${stageID}&only_active=true`,
  );
};
export const saveEditedTemplate = (dealID: number, payload: unknown) => {
  return axios.post(
    `/deals/${dealID}/override-communication-settings`,
    payload,
  );
};
export const deleteCommunications = (dealID: number) => {
  return axios.post(`/config/communications/${dealID}/delete`, {});
};
export const createCommunications = (data: unknown) => {
  return axios.post(`/config/communications/create`, data);
};
export const updateCommunications = (data: unknown, dealStageID: number) => {
  return axios.post(`/config/communications/${dealStageID}/update`, data);
};
export const updateCommunicationsToggle = (
  elementID: number,
  payload: unknown,
) => {
  return axios.post(`/config/communications/${elementID}/set-state`, payload);
};
export const templatesUpload = (template: File) => {
  const upload = new FormData();
  upload.append("upload", template);
  return axios.post(`/config/templates/upload`, upload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getPreview = (text: string, templateId: number) => {
  return axios.post(`/config/templates/${templateId}/preview`, {
    text,
  });
};
export const getEmailTemplates = () => {
  return axios.get(`/config/templates`);
};
//SURVEY//
export const saveSurveyData = (data: unknown) => {
  return axios.post(`/deals/survey/submit`, data);
};
export const getSurveyData = (id: number) => {
  return axios.get(`/deals/${id}/survey`);
};

export const saveDealNumbers = (data: unknown) => {
  return axios.post("/config/deal-numbers/update", data);
};
export const saveOneDealNumber = (data: unknown) => {
  return axios.post("/config/deal-numbers/update-one", data);
};
export const saveGroupDealNumber = (data: unknown) => {
  return axios.post("/config/deal-numbers/update-group", data);
};

export const getDealNumbers = async () => {
  return axios.get<DealNumber>("/config/deal-numbers");
};

/** Moving the api geolocation thing to the one place because fixing one thing 3 times is ridiculously ineffective  */
export function getIpGeoLocation(
  onLoad = (data: unknown) => {},
  onError = (err: unknown) => {},
) {
  axios
    .get(
      "https://api.ipgeolocation.io/ipgeo?apiKey=43ce5b26b7a740088bbb37710f15dbd8",
    )
    .then((res) => {
      onLoad(res.data);
    })
    .catch((error) => {
      const err = error?.response?.data?.error;
      if (err) {
        onError(err);
      } else {
        console.error("ip geolocation unknown error [2]", error);
        onError("Geolocation unknown error");
      }
    });
}

export function getEvents(
  startDate: string,
  endDate: string,
  regions: string,
  staff: string,
  dealTypes: string,
  selectedStaffUserIdString: string,
  calendarTimezone: string,
) {
  return axios.get(
    `/get-all-events?startDateTime=${startDate}&endDateTime=${endDate}&region=${
      regions === null ? null : regions.toString()
    }&role=${staff}&dealType=${dealTypes.toString()}&users=${selectedStaffUserIdString}&timezone_calendar=${calendarTimezone}`,
  );
}

export function getFolders(
  dealFolderId: number | null,
  searchValue: string | undefined,
  page: number,
  dealId: number | undefined,
  nopagination: boolean,
  onlyFolder = false,
) {
  let url;
  const search = searchValue ?? "";
  const dict = {
    dealId,
    dealFolderId,
    search: search.length === 0 ? undefined : search,
    page,
    per_page: nopagination ? 100000000 : undefined,
    onlyFolder,
  };
  const queryString = makeQueryString(dict);

  url = `/deal-files/list${queryString}`;

  return axios.get(url).then((res) => {
    return res.data;
  });
}

export function getFolderInfo(folderID: number) {
  const url = `/deal-files/${folderID}`;
  return axios.get(url).then((res) => {
    return res.data;
  });
}

export function getFolderSizeA(folderID: number) {
  const url = `/deal-files/info/folder/${folderID}/size`;
  return axios.get(url).then((res) => {
    return res.data;
  });
}

export const getClientsByFullName = (fullName: string) => {
  return axios.get(`/file-info/get-clients?search=${fullName}`);
};

export const getDealTypesByClientId = (clientId: number) => {
  return axios.get(`/file-info/get-deal-types?clientId=${clientId}`);
};

export const getAssignUsersByFullName = (dealId: number, fullName: string) => {
  return axios.get(
    `/file-info/get-assign-users?dealId=${dealId}&search=${fullName}`,
  );
};

export const uploadNewDocument = (data: number) => {
  return axios.post("/deal-files/upload", data);
};

export function deleteFile(ID: number) {
  const url = `/deal-files/${ID}/delete`;
  return axios.post(url).then((res) => {
    return res.data;
  });
}

export function renameFile(ID: number, ranameValue: string) {
  const url = `/deal-files/file/rename`;
  return axios.post(url, {dealFileId: ID, newName: ranameValue});
}
export const getWorkdocLink = (dealFileId: number) => {
  return axios.post(`/deal-files/share/link`, {
    dealFileId,
  });
};
export const requestAccessToDealFiles = (dealId: number) => {
  return axios.post(`/deals/${dealId}/request-workdocs-access`);
};
export const getEditWorkdocLink = (dealFileId: number) => {
  return axios.post(`/deal-files/edit/link`, {
    dealFileId,
  });
};
export function downloadStorageFolder(folderID: number) {
  const url = `/deal-files/download/folder/${folderID}`;
  return axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      const type = res.headers?.["Content-Type"] ?? "text/plain";
      return new Blob([res.data], {type});
    });
}
export function downloadStorageFile(ID: number) {
  const url = `/deal-files/download/file/${ID}`;

  return axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      const type = res.headers?.["content-type"] ?? "text/plain";
      return new Blob([res.data], {type});
    });
}

export const getShortcodes = () => {
  return axios.get(`/config/communications/get-shortcodes`);
};

export const moveFile = (idFile: number, idFolder: number) => {
  return axios
    .post(`/deal-files/${idFile}/move`, {
      newFolderId: idFolder,
    })
    .catch((error) => {
      console.log(error);
    });
};

export const moveFiles = (checkedFiles: unknown, idFolder: unknown) => {
  return axios.post(`/deal-files/move/files`, {
    files: checkedFiles,
    newFolderId: idFolder,
  });
};
export function downloadStorageFiles(checkedFiles: number[]) {
  let queryString = "";
  for (let i = 0; i < checkedFiles.length; i++) {
    if (i === checkedFiles.length - 1)
      queryString = queryString + checkedFiles[i];
    else queryString = queryString + checkedFiles[i] + ",";
  }

  const url = `/deal-files/download/files?files=${queryString}`;
  return axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      const type = res.headers?.["Content-Type"] ?? "text/plain";
      return new Blob([res.data], {type});
    });
}
export function getInteralIds(idsDisplayingFolders: number[]) {
  let queryString = "";
  for (let i = 0; i < idsDisplayingFolders.length; i++) {
    if (i === idsDisplayingFolders.length - 1)
      queryString = queryString + idsDisplayingFolders[i];
    else queryString = queryString + idsDisplayingFolders[i] + ",";
  }

  if (!queryString) {
    return Promise.resolve({data: null});
  }
  return axios
    .get(`/deal-files/folder/children?folders=${queryString}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

// DEALS API //
export const deleteDealArchive = (deleteID: number) => {
  return axios.post(`/deals/${deleteID}/delete`);
};

export const getArchivedDeals = (
  query = "",
  page: number,
  sortBy: unknown,
  sortOrder: unknown,
  user_id: number | null,
) => {
  const url = makeQueryString({
    page,
    sortBy,
    sortOrder: sortOrder ? "desc" : "asc",
    search: query,
    archive: true,
    user_id,
  });
  return axios.get<DealsResponse>(`/deals${url}`);
};

export const getListProfessionalsByField = (
  field: string,
  searchValue: string,
) => {
  return axios.get(
    `/autocomplete/team?role=Referring Professional&field=${field}&search=${searchValue}`,
  );
};
// CONFIG USERS //
export const userUpdate = (baseURL: string, id: number, payload: unknown) => {
  return axios.post(`${baseURL}/config/users/${id}/update`, payload);
};
export const userCreate = (baseURL: string, payload: unknown) => {
  return axios.post(`${baseURL}/config/users/create`, payload);
};

// INTAKE FORM
export function postEnteredField(client_id: number, tmp_data: unknown) {
  return axios.post(`/intake/save-data`, {
    client_id,
    tmp_data,
  });
}
export const createTransactionsAndGetBerbixTokens = (payload: unknown) => {
  return axios.post(`/berbix/intake/create`, payload);
};
//CALENDAR
export const getFullDealInfo = (id: number) => {
  return axios.get(`/get-event/${id}/full-info`);
};
export const getClosedDealsByWeekOrMonth = ({
  startDay,
  endDay,
  regions,
  role,
  dealType,
}: {
  startDay: unknown;
  endDay: unknown;
  regions: string | string[];
  role: string;
  dealType: string;
}) => {
  return axios.get(
    `/deals/closed?closing_date_from=${startDay}&closing_date_to=${endDay}&region=${regions.toString()}&role=${role}&deal_type=${dealType}`,
  );
};
export const getClosedDealsByDay = ({
  day,
  regions,
  role,
  dealType,
}: {
  day: string;
  regions: string[] | string;
  role: string;
  dealType: string;
}) => {
  return axios.get<Array<ClosingDateType>>(
    `/deals/closed?closing_date=${day}&region=${regions.toString()}&role=${role}&deal_type=${dealType}`,
  );
};
export const getLewClerksDealsNumber = ({
  startDay,
  endDay,
  regions,
  role,
  dealType,
}: {
  startDay: unknown;
  endDay: unknown;
  regions: string | string[];
  role: string;
  dealType: string;
}) => {
  return axios.get(
    `/deals/lawclerk-deals-cont?date_from=${startDay}&date_to=${endDay}&region=${regions.toString()}&role=${role}&deal_type=${dealType}`,
  );
};

export const updateAppointment = (
  dealID: number,
  appointment: unknown,
  payload: unknown,
) => {
  return axios.post(
    `/deals/${dealID}/update-appointment/${appointment}`,
    payload,
  );
};

export const createClosingDate = (payload: unknown) => {
  return axios.post(`/calendar-access/create`, payload).catch((e) => {
    return e;
  });
};
export const updateClosingDate = (closing_id: number, payload: unknown) => {
  return axios
    .post(`/calendar-access/${closing_id}/update`, payload)
    .catch((e) => {
      return e;
    });
};
export const deleteClosingDate = (closing_id: number) => {
  return axios.post(`/calendar-access/${closing_id}/delete`);
};
export const getClosingDateList = (
  date_from: string,
  date_to: string,
  region: string,
  calendarTimezone: string,
  state?: string[],
  user?: number,
) => {
  return axios.post(`/calendar-access`, {
    date_from,
    date_to,
    region: region || null,
    state,
    user,
    timezone_calendar: calendarTimezone,
  });
};
//DEAL STAGES
export const passMortgage = (dealId: number, deal_stage_id: number) => {
  return axios.post(`/deals/${dealId}/set-single-stage`, {
    deal_stage_id,
  });
};
//DEAL INFO LENDER
export const getListDealLenders = (searchValue: string) => {
  return axios.get(`/autocomplete/static-lender?search=${searchValue}`);
};
//IDV PAGE
export const getIdvData = (dealId: number) => {
  return axios.get(`/berbix/${dealId}/idv`);
};
export const sendEmailBerbixLink = (
  berbixTransactionId: number,
  deal: number,
) => {
  return axios.post(`/berbix/${berbixTransactionId}/${deal}/send-link`);
};
export const getIdvImageData = (imageId: number) => {
  return axios.get(`/agreements/${imageId}/preview`);
};

// QUOTE
export const createLead = (payload: object) => {
  return axios.post<QuoteResult>(`/integrations/leads`, payload);
}

export const leadUploadAgreement = (agreements: AgreementFile[]) => {
    return axios.post<QuoteResult>(`/integrations/leads/upload-agreements`, { agreements });
};

export const getQuote = (type: string, province: string, payload: object) => {
  return axios.post(`/quote/${type}/${province}`, payload);
}

export const convertLealToDealByClient = (dealUuid: string) => {
  return axios.post<DealConvertedByClient>(`/integrations/deals/${dealUuid}`, { convertFromLead: true });
};

export const createCustomerAccount = (client_id: string, password: string) => {
  return axios.post(`/auth/register/client`, { client_id, password });
};

export const getAgreementsByDeal = (id: number) => {
  return axios.get(`/deals/${id}/agreements`);
};

// Intake
export const intakeRequestAccessed = (client_id: string) => {
  return axios.post(`/intake/accessed`, { client_id });
};
export const getExistingIntakeByToken = ( client_id: unknown) => {
  return axios.get(`/intake/validate-intake-token`, {params: { client_id }});
}
export const getIsIntakeRequestMigrated = (id: number|string) => {
    return axios.get<{ onboarding_uuid?: string; is_v1?: boolean }>(`/intake/${id}/is-migrated`);
};

// Client Intake
export const updateClientIntakeReview = (id: number, payload: unknown) => {
  return axios.put(`/client-intakes/${id}/review`, payload);
};
export const updateClientIntake = (id: number, payload: {
  content: {data: unknown, pageNo: number},
  state: ClientIntakeStates
}) => {
  return axios.put(`/client-intakes/${id}`, payload);
};

// Onboarding
export const updateClientOnboarding = (id: number, payload: {state: State}) => {
  return axios.put(`/onboardings/${id}`, payload);
};
export const sendClientAppointmentRequest = (id: number, payload: {weekdays: [], times: [], details: string}) => {
  return axios.post(`/onboardings/${id}/request-appointment`, payload);
};
export const getClientOnboarding = (id: string|number, params: {with?: string}) => {
  return axios.get(`/onboardings/${id}`, {params});
};
export const saveClientIDV = (onboardingId: number, payload: unknown) => {
  return axios.post(`/onboardings/${onboardingId}/user-idvs`, payload);
};
export const resumeIDV = (onboardingId: number, userIdvId: number) => {
  return axios.post(`/onboardings/${onboardingId}/user-idvs/${userIdvId}/resume`, []);
};

export const updateOnboardingTask = (id: string|number, status: OnboardingTaskStatus) => {
  return axios.put<{success?: boolean, web_url?: string, task: OnboardingTask}>(`/onboardings/tasks/${id}`, {status});
};

export const updateDocumentAgreement = (id: number, payload: unknown) => {
  return axios.post(`/agreements/${id}`, payload);
};

export const deleteAgreementFile = (id: number) => {
  return axios.post<DocumentType[]>(`/agreements/${id}/delete-file`);
};

// Conveyance
export const getConveyanceHealth = () => {
  let url = `${configuration.conveyanceUrl}/health`;
  return axios.get(url);
};
export const sendDashDealToConveyance = (dealId: number) => {
  let url = `${configuration.conveyanceUrl}/deals/dashboard-deals/import`;
  return axios.post(url, { deal_id: dealId });
};
export const addClientsToDeal = (dealId: number, payload: unknown) => {
  return axios.post<UserParticipant[]>(`/deals/${dealId}/add-clients`, payload);
};
export const updateDealClient = (dealId: number, payload: unknown) => {
  return axios.put<UserParticipant[]>(`/deals/${dealId}/update-client`, payload);
};
export const deleteDealContact = (dealId: number, contactId: number) => {
  return axios.delete<UserParticipant[]>(`/deals/${dealId}/contacts/${contactId}`);
};
export const updateDealContactDraft = (dealId: number, draftId: number, payload: unknown) => {
  return axios.post<{message: string}>(`/deals/${dealId}/contact-drafts/${draftId}`, payload);
};
export const deleteDealContactDraft = (dealId: number, draftId: number) => {
  return axios.delete<{message: string}>(`/deals/${dealId}/contact-drafts/${draftId}`);
};
export const makeClientPrimary = (dealId: number, payload: {contact_id: number}) => {
  return axios.post(`/deals/${dealId}/make-primary`, payload);
};
export const sendOnboardingInvite = (dealId: number, contactId: number) => {
  return axios.post<UserParticipant[]>(`/deals/${dealId}/onboardings`, {
    contact_id: contactId,
  });
};
export const getProfessionalPerks = (params: {max?: number}) => {
    return axios.get(`/professional-perks`, {params});
};